<template>
    <div class="drivingWarp">
        <div class="witheBg">
            <img :src="img">
            <van-cell title="已扣分数" :value="$route.query.score"></van-cell>
            <van-cell title="驾驶证号" :value="$route.query.jszh">

            </van-cell>
            <van-cell title="档案编号" :value="$route.query.dabh"></van-cell>
            <van-cell title="更新日期" :value="updateTime"></van-cell>
        </div>
        <p>
            温馨提示：驾驶证累计扣满12分的，应当在15日内到交管部门参加道路安全法律、法规和相关知识学习，并参加考试
        </p>
        <div class="publicBtnDiv">
            <van-button class="publicBtnActive"   round block @click="$router.push('/main/homePage')">完 成</van-button>
        </div>

    </div>
    
</template>

<script>
    import img0 from '../../assets/img/driving/0@2x.png'
    import img1 from '../../assets/img/driving/1@2x.png'
    import img2 from '../../assets/img/driving/2@2x.png'
    import img3 from '../../assets/img/driving/3@2x.png'
    import img4 from '../../assets/img/driving/4@2x.png'
    import img5 from '../../assets/img/driving/5@2x.png'
    import img6 from '../../assets/img/driving/6@2x.png'
    import img7 from '../../assets/img/driving/7@2x.png'
    import img8 from '../../assets/img/driving/8@2x.png'
    import img9 from '../../assets/img/driving/9@2x.png'
    import img10 from '../../assets/img/driving/10@2x.png'
    import img11 from '../../assets/img/driving/11@2x.png'
    import img12 from '../../assets/img/driving/12@2x.png'
    import common from "../../utils/common";
    import {uwStatisticAction} from "../../utils/util";
    export default {
        name: "drivingResult",
        data(){
            return{
                img:'',
                img0:img0,
                img1:img1,
                img2,
                img3,
                img4,
                img5,
                img6,
                img7,
                img8,
                img9,
                img10,
                img11,
                img12,
                jszh:'',
                dabh:'',
                updateTime:''

            }
        },
        mounted() {
            this.updateTime = common.time(new Date)
           switch (parseInt(12-parseInt(this.$route.query.score))) {
               case 0:
                   this.img = this.img0
                   return
               case 1:
                   this.img = this.img1
                   return
               case 2:
                   this.img = this.img2
                   return
               case 3:
                   this.img = this.img3
                   return
               case 4:
                   this.img = this.img4
                   return
               case 5:
                   this.img = this.img5
                   return
               case 6:
                   this.img = this.img6
                   return
               case 7:
                   this.img = this.img7
                   return
               case 8:
                   this.img = this.img8
                   return
               case 9:
                   this.img = this.img9
                   return
               case 10:
                   this.img = this.img10
                   return
               case 11:
                   this.img = this.img11
                   return
               case 12:
                   this.img = this.img12
                   return


           }
           uwStatisticAction('/drivingResult','驾驶证查询结果')

        }
    }
</script>

<style scoped lang="less">
     .drivingWarp{
         height: 100%;
         background:#F5F5F5 ;
         padding-bottom: 70px;
         .witheBg{
             padding:  20px 0;
             background: #ffffff;
         }


         img{
             width: 100%;
         }
         p{
             font-size:12px;
             font-family:PingFangSC-Regular,PingFang SC;
             font-weight:400;
             color:#999999;
             padding: 12px;
         }

    }

</style>
<style>
    .drivingWarp .van-cell .van-cell__title span{
        font-size:15px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color: #999;
    } .drivingWarp .van-cell .van-cell__value span{
        font-size:15px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color: #333;
    }
</style>